<template>
  <div
    class="image-viewer"
    @mousewheel="e => onMouseWheel(e, this)"
  >
    <div
      class="image-viewer__percent body-1"
      :class="{
        'image-viewer__percent--show': showPercent
      }"
    >
      <span class="py-1 px-3 caption d-flex justify-center" style="background: #626068; border-radius: 8px; text-align: center; min-width: 55px;">{{ parseInt(zoomLevel * 100) }}%</span>
    </div>
    <div
      class="image-viewer__container"
      :style="styleObject"
      @mouseup="onMouseUp"
      @mousedown="onMouseDown"
      @mousemove="onMouseMove"
      :class="{
        'image-viewer--is-dragging': isMouseDown
      }"
    >
      <img
        :src="src"
        @load="onLoad"
        @error="onError"
        ref="item"
        class="image-viewer__img"
      />
    </div>
  </div>
</template>
<script>

import { normalizeWheel } from '@/api/normalizeWheel'
import lodashThrottle from 'lodash.throttle'
import lodashDebounce from 'lodash.debounce'

const defaultZoomLevelMin = 0.1,
    defaultZoomLevelMax = 6

export default {
  props: {
    linearZoomArray: {
      type: Array,
      required: true
    },
    zoom: {
      required: true
    },
    src: {
      type: String,
      required: true
    }
  },
  name: 'ImageViewer',
  data: () => ({
    stopScrolling: false,
    zoomType: 'image',
    unitIndex: 0,
    numAnimations: 0,
    animations: {},
    loaded: false,
    styleObject: {},
    isInitial: true,
    mouseZoomedIn: false,
    renderMaxResolution: false,
    offset: {
      x: 0,
      y: 0,
    },
    currentWindowScrollY: 0,
    midZoomPoint: {
      x: 0,
      y: 0
    },
    currPanDist: {
      x: 0,
      y: 0
    },
    mainScrollPos: {
      x: 0,
      y: 0
    },
    item: {
      w: null,
      h: null
    },
    v: null,
    h: null,
    startPanOffset: {
      x: 0,
      y: 0
    },
    panOffset: {
      x: 0,
      y: 0
    },
    bounds: {
      min: {
        x: 0,
        y: 0,
      },
      center: {
        x: 0,
        y: 0
      },
      max: {
        x: 0,
        y: 0
      }
    },
    initialPosition: {
      x: 0,
      y: 0
    },
    panAreaSize: {
      x: 0,
      y: 0
    },
    viewportSize: {
      x: 0,
      y: 0
    },
    showPercent: false,
    zoomLevelMin: defaultZoomLevelMin,
    zoomLevel: 100,
    zoomLevelMax: defaultZoomLevelMax,
    fitRatio: null,
    scaleMode: 'fit',
    maxSpreadZoom: 1.33,
    currZoomLevel: 1,
    startZoomLevel: 1,
    initialZoomLevel: 1,
    loadError: false,
    isMouseDown: false,
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0
  }),
  created() {
    window.addEventListener('resize', this.onResize)
  },
  destroyed() {
      this.nonLinearZoomArray.splice(0)
      window.removeEventListener('resize', this.onResize)
  },
  computed: {
    nonLinearZoomArray() {
      return this.linearZoomArray
    },
    sliderModel: {
      get() {
        return this.zoom
      },
      set(val) {
        this.$emit('updateZoom', val)
      }
    }
  },
  methods: {
    onMouseUp() {
      this.isMouseDown = false
    },
    onMouseDown(e) {
      const { pageX, pageY } = e
      this.isMouseDown = true
      const scrollContent = document.querySelector('.item-dialog__content.custom-scroll')
      this.startX = pageX - scrollContent.offsetLeft
      this.startY = pageY - scrollContent.offsetTop
      this.scrollLeft = scrollContent.scrollLeft
      this.scrollTop = scrollContent.scrollTop
    },
    onMouseMove(e) {
      if (!this.isMouseDown) return
      e.preventDefault()

      const { pageX, pageY } = e
      const scrollContent = document.querySelector('.item-dialog__content.custom-scroll')

      const x = pageX - scrollContent.offsetLeft
      const walkX = x - this.startX

      const y = pageY - scrollContent.offsetTop
      const walkY = y - this.startY

      scrollContent.scrollLeft = this.scrollLeft - walkX
      scrollContent.scrollTop = this.scrollTop - walkY
    },
    onMouseWheel: lodashThrottle((e, context) => {
      // const percent = parseInt(context.zoomLevel * 100)
      // if (percent >= 90 && percent <= 110 && e.wheelDeltaY < 100) {
      //   context.zoomLevel = 1
      // }
      context.onMouseWheelHandle(e, context)
    }, 10),
    afterMouseWheel: lodashDebounce(cb => cb(), 50),
    onMouseWheelHandle(e, context) {
      e.preventDefault()
      const index = this.unitIndex + 1
      const currentSliderModel = this.sliderModel + 1
      const minSliderModel = index - 2
      const maxSliderModel = index + 4
      const from = currentSliderModel >= minSliderModel
      const to = currentSliderModel <= maxSliderModel
      const between = from && to
      const { spinY } = normalizeWheel(e)
      const step = Math.ceil(Math.abs(spinY))

      if (Math.abs(spinY) >= 0.05) {
        this.stopScrolling = false
      }

      const func = () => {
        const { ctrlKey } = e
        if (context.zoomType === 'document') {
          if  (!ctrlKey) return
        } else {
          if (ctrlKey) return
        }

        const { deltaY } = e
        
        if (deltaY < 0) {
          context.sliderModel += step
        } else {
          context.sliderModel -= step
        }
      }

      !this.stopScrolling && func()
      this.afterMouseWheel(() => {
        if (between) {
          this.stopScrolling = true
          if (this.stopScrolling) {
            this.sliderModel = index
          }
        }
      })
    },
    setTranslateX(x, elStyle) {
      elStyle.left = x + 'px';
    },
    init() {
      //this.setupTransforms()
      this.updateSize()
      //this.updateCurrItem()
    },
    setupTransforms() {},
    moveMainScroll(x) {
      this.mainScrollPos.x = x
    },
    calculateItemSize() {
      if (!this.loaded) return
      if (this.src && !this.loadError) {
        this.panAreaSize.x = this.viewportSize.x
        this.panAreaSize.y = this.viewportSize.y

        const hRatio = this.panAreaSize.x / this.item.w
        const vRatio = this.panAreaSize.y / this.item.h
        const percentOfScreen = 0.8

        this.fitRatio = (hRatio < vRatio ? hRatio : vRatio) * percentOfScreen

        if (this.item.h >= (this.item.w * 2) && this.item.h * hRatio >= this.panAreaSize.y) { // IS DOCUMENT
          this.zoomType = 'document'
          this.fitRatio = hRatio * percentOfScreen
        }

        if (this.panAreaSize.x > this.item.w && this.panAreaSize.y > this.item.h) {
          this.fitRatio = 1
        } 

        this.zoomLevelMin = this.fitRatio * defaultZoomLevelMin
        this.zoomLevel = this.fitRatio
        this.zoomLevelMax = this.fitRatio * defaultZoomLevelMax
        this.nonLinearZoomArray.push(this.zoomLevelMin)

        const coefMin = (this.fitRatio - this.zoomLevelMin) / 100
        const coefMax = (this.zoomLevelMax - this.fitRatio) / 100
        
        for (let i = 1; i <= 99; i += 1) {
          this.nonLinearZoomArray.push(this.zoomLevelMin + i * coefMin)
        }

        for (let m = 1; m <= 100; m += 1) {
          this.nonLinearZoomArray.push(this.fitRatio + m * coefMax)
        }

        let isFinded = false

        this.nonLinearZoomArray.forEach((i, index) => {
          const floored = Math.floor(i)
          if (isFinded || i < 1) return
          isFinded = floored === 1
          this.unitIndex = index
          this.nonLinearZoomArray[this.unitIndex] = 1
        })

        //console.log("calculateItemSize -> this.nonLinearZoomArray", )

        // const bounds = this.calculateSingleItemPanBounds(this.item.w * this.zoomLevel, this.item.h * this.zoomLevel)
        
        // if (isInitial) {
        //   this.initialPosition = bounds.center
        // }

      }

      return this.bounds
    },
    setImageSize(maxRes) {
      const w = maxRes ? this.item.w : Math.round(this.item.w * this.fitRatio)
      const h = maxRes ? this.item.h : Math.round(this.item.h * this.fitRatio)

      const { item } = this.$refs
      item.style.width = w + 'px'
      item.style.height = h + 'px'
    },
    // applyZoomPan(zoomLevel,panX,panY,allowRenderResolution) {
    //   this.panOffset.x = panX
    //   this.panOffset.y = panY
    //   this.currZoomLevel = zoomLevel
    //   this.applyCurrentZoomPan( allowRenderResolution )
    // },
    applyZoomPan () {
      const { container } = this.$refs
			var zoomLevel = this.fitRatio > 1 ? 1 : this.fitRatio,
				s = container.style,
				w = zoomLevel * this.item.w,
				h = zoomLevel * this.item.h
			s.width = w + 'px'
			s.height = h + 'px'
			s.left = this.initialPosition.x + 'px'
			s.top = this.initialPosition.y + 'px'
		},
    applyZoomToItem() {
      const { container } = this.$refs

      if (container) {
        this.applyZoomTransform(
          this.initialPosition.x, 
          this.initialPosition.y, 
          this.currZoomLevel
        )
      }
    },
    applyCurrentZoomPan( allowRenderResolution ) {
        if(allowRenderResolution) {
          if(this.currZoomLevel > this.fitRatio) {
            if (!this.renderMaxResolution) {
              //this.setImageSize(true)
              this.renderMaxResolution = true
            }
          } else {
            if(this.renderMaxResolution) {

              //this.setImageSize()
              this.renderMaxResolution = false
            }
          }
          
        }
        
        this.applyZoomTransform(this.panOffset.x, this.panOffset.y, this.currZoomLevel)
    },
    applyZoomTransform() {
      const transformOrigin = this.zoomType === 'image' ? 'center' : 'center top'
      const translateY = this.zoomType === 'image' ? -50 : 0
      const top = this.zoomType === 'image' ? '50%' : 0
      const transform = `translate(-50%, ${translateY}%) scale(${this.zoomLevel})`
      this.styleObject = {
        top,
        transformOrigin,
        transform
      }
    },
    calculateSingleItemPanBounds(realPanElementW, realPanElementH) {
      this.bounds.center.x = Math.round((this.panAreaSize.x - realPanElementW) / 2)
      this.bounds.center.y = Math.round((this.panAreaSize.y - realPanElementH) / 2)

      // maximum pan position
      this.bounds.max.x = (realPanElementW > this.panAreaSize.x) ? 
                Math.round(this.panAreaSize.x - realPanElementW) : 
                this.bounds.center.x
      
      this.bounds.max.y = (realPanElementH > this.panAreaSize.y) ? 
                Math.round(this.panAreaSize.y - realPanElementH) : 
                this.bounds.center.y
      
      // minimum pan position
      this.bounds.min.x = (realPanElementW > this.panAreaSize.x) ? 0 : this.bounds.center.x
      this.bounds.min.y = (realPanElementH > this.panAreaSize.y) ? 0 : this.bounds.center.y

      return this.bounds
    },
    roundPoint(p) {
      p.x = Math.round(p.x)
      p.y = Math.round(p.y)
    },
    equalizePoints(p1, p2) {
      p1.x = p2.x
      p1.y = p2.y
      if(p2.id) {
        p1.id = p2.id
      }
    },
    calculatePanBounds(zoomLevel, update) {
      var bounds = this.calculateItemSize( zoomLevel )
      if(update) {
        update
        // _currPanBounds = bounds
      }
      return bounds
    },
    setScrollOffset(x, y) {
      this.offset.x = x
      this.currentWindowScrollY = this.offset.y = y
    },
    calculatePanOffset(axis, zoomLevel) {
      var m = this.midZoomPoint[axis] - this.offset[axis]
      return this.startPanOffset[axis] + this.currPanDist[axis] + m - m * ( zoomLevel / this.startZoomLevel )
    },
    modifyDestPanOffset(axis, destPanBounds, destPanOffset, destZoomLevel) {
      if(destZoomLevel === this.initialZoomLevel) {
        destPanOffset[axis] = this.initialPosition[axis]
        return true
      } else {
        destPanOffset[axis] = this.calculatePanOffset(axis, destZoomLevel)

        if(destPanOffset[axis] > destPanBounds.min[axis]) {
          destPanOffset[axis] = destPanBounds.min[axis]
          return true
        } else if(destPanOffset[axis] < destPanBounds.max[axis] ) {
          destPanOffset[axis] = destPanBounds.max[axis]
          return true
        }
      }
    },
    getScrollY() {
      const yOffset = window.pageYOffset
      return yOffset !== undefined ? yOffset : document.documentElement.scrollTop
    },
    updatePageScrollOffset() {
      this.setScrollOffset(0, this.getScrollY())
    },
    updateSize() {
      this.viewportSize.x = window.innerWidth
      this.viewportSize.y = window.innerHeight

      //this.updatePageScrollOffset()

      this.startZoomLevel = this.currZoomLevel = this.initialZoomLevel
      this.currPanBounds = this.bounds

      if(this.currPanBounds) {
        this.panOffset.x = this.currPanBounds.center.x
        this.panOffset.y = this.currPanBounds.center.y
        this.applyCurrentZoomPan( true )
      }

      this.applyZoomToItem()
      this.calculateItemSize()
      // this.setImageSize()
      //this.applyZoomPan()
      // _shout('resize');

      return this.bounds
    },
    updateCurrZoomItem() { 		
      this.currPanBounds = this.bounds
      this.startZoomLevel = this.currZoomLevel = this.initialZoomLevel

      this.panOffset.x = this.currPanBounds.center.x
      this.panOffset.y = this.currPanBounds.center.y
    },
    updateCurrItem() {
      this.renderMaxResolution = false
      this.updateCurrZoomItem()
    },
    zoomTo(destZoomLevel, centerPoint, speed, easingFn) {
      if(centerPoint) {
        this.startZoomLevel = this.currZoomLevel
        this.midZoomPoint.x = Math.abs(centerPoint.x) - this.panOffset.x
        this.midZoomPoint.y = Math.abs(centerPoint.y) - this.panOffset.y
        this.equalizePoints(this.startPanOffset, this.panOffset)
      }

      const destPanBounds = this.calculatePanBounds(destZoomLevel, false),
        destPanOffset = {}

      this.modifyDestPanOffset('x', destPanBounds, destPanOffset, destZoomLevel)
      this.modifyDestPanOffset('y', destPanBounds, destPanOffset, destZoomLevel)

      var initialZoomLevel = this.currZoomLevel
      var initialPanOffset = {
        x: this.panOffset.x,
        y: this.panOffset.y
      }

      this.roundPoint(destPanOffset)

      var onUpdate = (now) => {
        if(now === 1) {
          this.currZoomLevel = destZoomLevel
          this.panOffset.x = destPanOffset.x
          this.panOffset.y = destPanOffset.y
        } else {
          this.currZoomLevel = (destZoomLevel - initialZoomLevel) * now + initialZoomLevel
          this.panOffset.x = (destPanOffset.x - initialPanOffset.x) * now + initialPanOffset.x
          this.panOffset.y = (destPanOffset.y - initialPanOffset.y) * now + initialPanOffset.y
        }

        this.applyCurrentZoomPan( now === 1 )
      }

      this.animateProp('customZoomTo', 0, 1, speed, easingFn, onUpdate)
    },
    getDoubleTapZoom(isMouseClick) {
      if(isMouseClick) {
        return 1.33
      } else {
        return this.initialZoomLevel < 0.7 ? 1 : 1.33
      }
    },
    getCurrentTime() {
      return new Date().getTime()
    },
    requestAF(r) {
      requestAnimationFrame(r)
    },
    cancelAF(r) {
      cancelAnimationFrame(r)
    },
    stopAnimation(name) {
      if(this.animations[name]) {
        if(this.animations[name].raf) {
          this.cancelAF( this.animations[name].raf )
        }
        this.numAnimations--
        delete this.animations[name]
      }
    },
    registerStartAnimation(name) {
      if(this.animations[name]) {
        this.stopAnimation(name)
      }
      if(!this.animations[name]) {
        this.numAnimations++;
        this.animations[name] = {}
      }
    },
    stopAllAnimations() {
      for (var prop in this.animations) {

        if( Object.prototype.hasOwnProperty.call(this.animations, prop) ) {
          this.stopAnimation(prop)
        } 
        
      }
    },
    animateProp(name, b, endProp, d, easingFn = k => - (Math.cos(Math.PI * k) - 1) / 2, onUpdate, onComplete) {
      var startAnimTime = this.getCurrentTime(), t
      this.registerStartAnimation(name)

      var animloop = () => {
        if ( this.animations[name] ) {
          
          t = this.getCurrentTime() - startAnimTime; // time diff
          //b - beginning (start prop)
          //d - anim duration

          if ( t >= d ) {
            this.stopAnimation(name);
            onUpdate(endProp);
            if(onComplete) {
              onComplete()
            }
            return
          }
          onUpdate( (endProp - b) * easingFn(t/d) + b )

          this.animations[name].raf = this.requestAF(animloop)
        }
      };
      animloop()
    },
    toggleDesktopZoom(e, centerPoint) {
			centerPoint = centerPoint || {
        x:this.viewportSize.x / 2,
        y:this.viewportSize.y / 2 
      }

			var doubleTapZoomLevel = this.getDoubleTapZoom(true)
			var zoomOut = this.currZoomLevel === doubleTapZoomLevel
			
      this.mouseZoomedIn = !zoomOut

      if (!zoomOut) {
        this.renderMaxResolution = false
      }

			this.zoomTo(zoomOut ? this.initialZoomLevel : doubleTapZoomLevel, centerPoint, 333)
		},
    onLoad(e) {
      const { target } = e
      const { naturalWidth, naturalHeight } = target
      this.item.w = naturalWidth
      this.item.h = naturalHeight
      this.loaded = true
    },
    onError() {
      this.loadError = true
    },
    onResize() {
      this.loaded && this.updateSize()
    },
    hideProgress: lodashDebounce(context => {
      context.showPercent = false
    }, 300)
  },
  watch: {
    sliderModel(to) {
      this.zoomLevel = this.nonLinearZoomArray[to - 1]
      this.showPercent = true
      this.hideProgress(this)
    },
    zoomLevel() {
      this.applyZoomTransform()
    },
    loaded(to) {
      to
      this.$nextTick(() => {
        this.init()
        // to && this.updateSize()
        // setTimeout(() => {
        //   this.isInitial = false
        // }, 333)
      })
    }
  }
}
</script>
<style lang="scss">
.image-viewer {
  height: 100%;
  width: 100%;

  &__percent {
    position: fixed;
    z-index: 1;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    top: 0px;
    opacity: 0;
    transition: opacity 0.3s;

    &--show {
      opacity: 1;
    }
  }

  &--is-dragging {
    cursor:  move;
  }

  &__container {
    position: absolute;
    left: 50%;
  }

  &__img {
    display: block;
    user-select: none;
    object-fit: none;
    box-shadow: 0 30px 60px -12px rgba(50,50,93,.55),0 18px 36px -18px rgba(0,0,0,.9),0 -12px 36px -8px rgba(0,0,0,.4)!important;
    max-width: initial;
  }

  &__slider {
    width: 300px;
    position: fixed;
    left: 50%;
    top: 0%;
    display: flex;
    transform: translateX(-50%);
    z-index: 1;
    padding-top: 7.5px;

    .v-input__append-outer {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .v-input {
      display: flex;
      align-items: center;
    }

  }

  &__container {

    // &.inited {
    //   transition: transform .333s;
    // }
  }
}
</style>