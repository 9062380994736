<template>
  <div
    class="image-view d-flex align-stretch"
    :class="{
      hideList,
      hideBackdrop
    }"
  >
    <image-viewer
      :zoom="zoomModel"
      :linear-zoom-array="$store.state.linearZoomArray"
      @updateZoom="val => zoomModel = val"
      :src="images[0]"
    />
    <!-- <viewer 
      :options="options"
      style="height: 100%; width: 100%;"
      :images="images"
      @inited="onInitedViewer"
      @viewed="onHiddenViewer"
      ref="viewer"
    >
      <template #default="scope">
        <grid-layout
          v-if="scope.images.length > 1 && showGrid"
          :options="gridOptions"
          :layout-options="gridLayoutOptions"
        >
          <img
            @click="() => show(index)"
            max-width="250px"
            height="250px"
            v-for="(src, index) in scope.images" 
            :key="`image-${src}-${index}`"
            :src="src"
          >
        </grid-layout>
        <img 
          v-else
          v-for="(src, index) in scope.images" 
          :key="`image-${src}-${index}`"
          :src="src"
          @load="e => onLoadImage(src)"  
        />
      </template>
    </viewer> -->
  </div>
</template>
<script>

import ImageViewer from '@/components/image-viewer'

export default {
  props: {
    value: {},
    hideList: {
      default: () => true
    },
    hideBackdrop: {
      default: () => true
    },
    images: {
      type: Array,
      required: true
    }
  },
  name: 'ItemDialogImageView',
  components: {
    ImageViewer
  },
  data: () => ({
    loaded: [],
    showGrid: false,
  }),
  mounted() {
    this.showGrid = true
  },
  computed: {
    zoomModel: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    optionsFromAttrs() {
      let result = {
        ...this.$attrs
      }
      return result
    },
    gridOptions() {
      return {
        transitionDuration: 0.2,
        useFit: true,
        useRecycle: false,
        horizontal: false,
        align: 'center',
        isOverflowScroll: false
      }
    },
    gridLayoutOptions() {
      return {
        margin: 16
      }
    },
    options() {
      return {
        inline: true,
        toolbar: false,
        navbar: false,
        button: false,
        movable: false,
        title: false,
        ...this.optionsFromAttrs
      }
    },
  },
  methods: {
    show(index) {
      this.$viewer.view(index)
    },
    onInitedViewer(viewer) {
      this.$viewer = viewer
    },
    onHiddenViewer() {
      console.log(123)
    },
    onLoadImage(src) {
      !this.loaded.includes(src) && this.loaded.push(src)
    }
  }
}
</script>
<style lang="scss">
.image-view {
  height: 100%;
  position: relative;
}

</style>